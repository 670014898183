<template>
  <div class="cdyy-jmcdw-container">
    <div class="common-part first-part">
      <section-title :titleInfo="firstPartTitleInfo"></section-title>
      <div class="gs-text">
        在降碳减排领域，交通运输行业是减排重点，加快交通行业实现低碳转型发展意义重大。在此背景下，于服务区、收费站等高速公路站点就地开发新能源，设置分布式光伏、储能等设备，建设充电基础设施，产生电能自发自用，余电上网，提供便捷充电服务等。本公司公路充电网解决方案将新能源、高速公路两个行业的系统相互融合，为当地多场景实施分布式光伏项目带来重要借鉴和示范意义。
      </div>
      <img class="gs-pic" src="../images/cdyy/gs-pic.png" alt="" />
    </div>

    <div class="common-part second-part">
      <section-title :titleInfo="secondPartTitleInfo"></section-title>

      <div class="second-part-cont">
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/glcd-ys-logo1.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">集成化一体式</div>
            <div class="spi-text">电站设计</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/glcd-ys-logo2.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">光储充放检</div>
            <div class="spi-text">综合能源站</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/glcd-ys-logo3.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">液冷大功率充电</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/glcd-ys-logo4.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">群管群控智能调度</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/glcd-ys-logo5.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">模块化设计灵巧</div>
            <div class="spi-text">便捷</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/glcd-ys-logo6.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">智能运维系统</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/glcd-ys-logo7.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">两层防护体系</div>
            <div class="spi-text">保障充电安全</div>
          </div>
        </div>
      </div>
    </div>

    <div class="common-part third-part">
      <section-title :titleInfo="thirdPartTitleInfo"></section-title>

      <div class="third-part-cont">
        <div class="third-left-part">
            <div class="left-part-title">
                公路充电
            </div>
            <div class="left-part-list">
                <div class="left-part-list-item" :class="{'left-part-list-item-active': thirdPartIndex == index}" v-for="(item, index) in leftPartList" :key="index" @click="handleClickThirdPart(item, index)">
                    {{ item.label }}
                </div>
            </div>
        </div>
        <div class="third-right-part">
            <div class="third-right-part-cont">
                <div class="part-cont-show-pic">
                    <img class="show-img" :src="showImg" alt="">
                </div>
                <div class="part-cont-show-text">
                    {{ showText }}
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: {
    sectionTitle,
  },
  data() {
    return {
      firstPartTitleInfo: {
        bigtext1: "概述",
      },
      secondPartTitleInfo: {
        bigtext1: "我们在公路充电领域的优势",
      },
      thirdPartTitleInfo: {
        bigtext1: "公路充电网解决方案",
      },
      thirdPartIndex:0,
      leftPartList:[
        {
            label:'服务区快速补电站',
            img: require("../images/cdyy/glcdw-part-fa-pic1.png"),
            text:'新建小区按政府要求配建一定比例的充电设施，智融提供优质设备和建设安装，满足建设单位验收需求。并提供充电设施运维管理服务，保障充电设施安全稳定运行。建管一体，放心无忧。'
        },
        {
            label:'大功率快充站',
            img: require("../images/cdyy/glcdw-part-fa-pic1.png"),
            text:'最高可达600kW充电功率，充电5分钟续航300公里，实现充电如加油般的体验。多方位安全保障，对冷却液、充电枪发热点等关键位置实时温度监测，确保充电过程安全；同时也会对冷却管道内压力、储液箱液位等进行监控，保证冷却循环系统的安全可靠。'
        },
        {
            label:'光储充智慧能源站',
            img: require("../images/cdyy/glcdw-part-fa-pic1.png"),
            text:'综合智慧设备智能物联，云数据管理技术。做到智能运维，智能防护，智能监控，智能调度。可以利用服务区闲置屋顶、车棚安装光伏电站，所发的电能进行自发自用余电上网，可以有效降低用电成本，降低碳排放。'
        },
        {
            label:'平台解决方案',
            img: require("../images/cdyy/glcdw-part-fa-pic1.png"),
            text:'安全监控可视化界面，可随时掌握充电情况，实现用能精细化管理，两层安全防护技术，让充电安全隐患做到可监视、可预测、可追溯。提供精细化数据及报表分析，多样化结算策略，满足多重运营及计费需求。'
        },
      ],
      showImg: require("../images/cdyy/glcdw-part-fa-pic1.png"),
      showText: '推荐480kW分体式充电机拖带一套液冷大功率充电终端+若干普通充电终端方案，采用群管群控技术，满足出行车辆快速补能需求。需要增容的服务区可采用高低压一体化充电箱变，节约投资成本，快速便捷施工。'
    };
  },
  methods:{
    handleClickThirdPart(item, index){
        this.thirdPartIndex = index
        this.showImg = item.img
        this.showText = item.text
    }
  }
};
</script>

<style lang="scss" scoped>
.cdyy-jmcdw-container {
  width: 100%;
  background-color: #fff;
  .common-part {
    width: 100%;
    box-sizing: border-box;
    padding: 100px 160px;
    position: relative;
  }
  .first-part {
    min-height: 584px;
    .gs-text {
      width: 1000px;
      font-weight: 400;
      font-size: 18px;
      color: #1d1d1d;
      margin-top: 30px;
      line-height: 32px;
    }
    .gs-pic {
      position: absolute;
      width: 362px;
      height: 584px;
      right: 190px;
      top: 0;
    }
  }
  .second-part {
    width: 100%;
    .second-part-cont {
      width: 100%;
      height: 237px;
      display: flex;
      justify-content: space-between;
      .second-part-item {
        flex: 1;
        margin-right: 20px;
        height: 237px;
        background-image: url("../images/cdyy/jmcd-ys-item-bg.png");
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .second-part-item-pic {
          width: 100%;
          height: 80px;
          text-align: center;
          line-height: 80px;
          margin-bottom: 30px;
          .part-item-pic {
            display: inline-block;
            width: 68px;
            height: 68px;
            margin-top: 10px;
          }
        }
        .second-part-item-cont {
          width: 100%;
          font-size: 18px;
          color: #1d1d1d;
          line-height: 36px;
          .spi-text {
            text-align: center;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .third-part {
    width: 100%;
    .third-part-cont {
      width: 100%;
      display: flex;
      .third-left-part {
        flex: 1;
        height: 618px;
        background-image: url("../images/cdyy/jmcd-fa-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        box-sizing: border-box;
        padding: 70px 80px;
        .left-part-title{
            width: 100%;
            font-weight: 400;
            font-size: 32px;
            color: #FFFFFF;
            line-height: 60px;
            margin-bottom: 30px;
        }
        .left-part-list{
            width: 100%;
            font-size: 18px;
            color: #D4E6FF;
            line-height: 60px;
        }
        .left-part-list-item{
            margin-bottom: 20px;
            position: relative;
            cursor: pointer;
        }
        .left-part-list-item-active{
            color: #FFFFFF;
            &::before{
                content: "";
                position: absolute;
                width: 13px;
                height: 7px;
                background-image: url('../images/cdyy/jmcd-fa-active-arrow.png');
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center;
                vertical-align: middle;
                left: -25px;
                top: 25px;
            }
        }
      }
      .third-right-part {
        flex: 1.2;
        height: 709px;
        background-color: #f6f6f6;
        box-sizing: border-box;
        padding: 20px 80px 0;
        .third-right-part-cont{
            width: 100%;
            height: 100%;
            background-color: #fff;
            box-sizing: border-box;
            padding: 40px;
            .part-cont-show-pic{
                width: 100%;
                height: 322px;
                margin-bottom: 35px;
                .show-img{
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                }
            }
            .part-cont-show-text{
                width: 100%;
                font-size: 18px;
                color: #1D1D1D;
                line-height: 36px;
            }
        }
      }
    }
  }
}
</style>



