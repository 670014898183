<template>
  <div class="sjyy-jnrh-container">
    <div class="common-part first-part">
        <section-title :titleInfo="firstPartTitleInfo"></section-title>
        <div class="gs-cont-text">
            交能融合是交通行业与能源行业的深度结合，在新能源汽车领域提出了智能引导和动态规划两个方向。针对用户需求个性化、交通实时多变等问题，基于“车-桩-人-路-网”知识图谱的充换电特征敏感度分析方法，建立充换电需求弹性评估模型，搭建基于深度强化学习的车群充换电引导及规划模型，从而提升充电桩利用率、减少用户排队时间。
        </div>
        <section-title :titleInfo="secondPartTitleInfo"></section-title>
        <div class="target-cont">
            <div class="target-cont-text">
                引导部分利用车辆 SOC 信息以及用户充电习惯召回充电场站，提升充电场站的召回率，根据车辆的充电偏好及场站的服务能力进行精排，构建精准排序模型，优先推荐符合用户期望的场站，从而提升用户对推荐场站的转化率。<br>规划部分通过分析规划区域充电负荷预测数据，应用充换电设施布局规划多目标优化模型，形成充（换）电站规划方案，最大程度满足用户充电需求。
            </div>
            <div class="target-cont-img">
              <img class="target-cont-pic" src="../images/sjyy/sjyy-jnrh-part-bg.png" alt="">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: {
    sectionTitle,
  },
  data(){
    return {
        firstPartTitleInfo: {
            bigtext1: "概述",
        },
        secondPartTitleInfo: {
            bigtext1: "目标",
        },
    }

  }
};
</script>

<style lang="scss" scoped>
.sjyy-jnrh-container {
  width: 100%;
  background-color: #fff;
  .common-part {
    width: 100%;
    box-sizing: border-box;
    padding: 100px 160px;
    position: relative;
  }
  .first-part{
    width: 100%;
    /* height: 1080px; */
    background-image: url('../images/nyjh/nyjh-xqxy-section-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    .gs-cont-text{
        width: 1000px;
        font-weight: 400;
        font-size: 18px;
        color: #1D1D1D;
        line-height: 32px;
        margin: 30px 0 150px;
    }
    .target-cont{
        width: 100%;
        display: flex;
        .target-cont-text{
            flex: 1;
            box-sizing: border-box;
            padding-right: 30px;
            font-weight: 400;
            font-size: 18px;
            color: #1D1D1D;
            line-height: 32px;
        }
        .target-cont-img{
            width: 878px;
            height: 553px;
            position: relative;
            /* background-image: url('../images/sjyy/sjyy-jnrh-part-bg.png');
            background-repeat: no-repeat;
            background-size: 1172px 553px;
            background-position: right; */
            .target-cont-pic{
              position: absolute;
              right: 0;
              bottom: 0;
              width: 1172px;
              height: 553px;
            }
        }

    }
  } 
}
</style>