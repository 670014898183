<template>
  <div class="cdyy-jmcdw-container">
    <div class="common-part first-part">
      <section-title :titleInfo="firstPartTitleInfo"></section-title>
      <div class="gs-text">
        城市公共交通和出租车、网约车成为人们出行及日常生活的主要工具，也是新能源汽车的主力军，针对公共交通和出租车、网约车的特点：需求充电时间短、充电频繁、充电稳定性高，智融科技推出了一体式及分体式大功率快充电桩解决方案，方案采用的站级管理系统可以实现对充电桩、车、配电侧等产品统一进行监控管理和调度，实现一体化运营。
      </div>
      <img class="gs-pic" src="../images/cdyy/gs-pic.png" alt="" />
    </div>

    <div class="common-part second-part">
      <section-title :titleInfo="secondPartTitleInfo"></section-title>

      <div class="second-part-cont">
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/ggcd-ys-logo1.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">集成化一体式</div>
            <div class="spi-text">充电桩设计</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/ggcd-ys-logo2.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">无桩充电无电插头</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/ggcd-ys-logo3.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">群管群控智能调度</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/ggcd-ys-logo4.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">模块化设计灵巧</div>
            <div class="spi-text">便捷</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/ggcd-ys-logo5.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">柔性充电技术</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/ggcd-ys-logo6.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">智能运维系统</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/ggcd-ys-logo7.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">平台大数据技术</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/ggcd-ys-logo8.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">液冷超充技术</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/ggcd-ys-logo9.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">两层防护体系</div>
            <div class="spi-text">保证安全</div>
          </div>
        </div>
      </div>
    </div>

    <div class="common-part third-part">
      <section-title :titleInfo="thirdPartTitleInfo"></section-title>

      <div class="third-part-cont">
        <div class="third-left-part">
            <div class="left-part-title">
                公共充电
            </div>
            <div class="left-part-list">
                <div class="left-part-list-item" :class="{'left-part-list-item-active': thirdPartIndex == index}" v-for="(item, index) in leftPartList" :key="index" @click="handleClickThirdPart(item, index)">
                    {{ item.label }}
                </div>
            </div>
        </div>
        <div class="third-right-part">
            <div class="third-right-part-cont">
                <div class="part-cont-show-pic">
                    <img class="show-img" :src="showImg" alt="">
                </div>
                <div class="part-cont-show-text">
                    {{ showText }}
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: {
    sectionTitle,
  },
  data() {
    return {
      firstPartTitleInfo: {
        bigtext1: "概述",
      },
      secondPartTitleInfo: {
        bigtext1: "我们在公共充电领域的优势",
      },
      thirdPartTitleInfo: {
        bigtext1: "公共充电网解决方案",
      },
      thirdPartIndex:0,
      leftPartList:[
        {
            label:'标准公快站',
            img: require("../images/cdyy/ggcdw-part-fa-pic1.png"),
            text:'建设在地面或地下公共停车场，主要设置充电箱变或群充设备。可根据现场电力容量、车辆充电需求灵活调配功率和终端数量，配合两层安全防护体系让爱车充电更安全高效。云平台具有强大引流能力，充分提升资产利用率。完善的智能运维体系有效提升充电场站运营效率'
        },
        {
            label:'液冷超充站',
            img: require("../images/cdyy/ggcdw-part-fa-pic1.png"),
            text:'建设在地面或地下公共停车场，主要设置充电箱变或群充设备，搭配液冷超充终端，提供超大功率快速充电体验。可根据现场电力容量、车辆充电需求灵活调配功率和终端数量，配合两层安全防护体系让爱车充电更安全高效。'
        },
        {
            label:'光储充放智慧微网公快站',
            img: require("../images/cdyy/ggcdw-part-fa-pic1.png"),
            text:'融合光-储-充-放多能于一体，搭载高效经济、数字增值的智慧能源管理平台，实现用能精细化管控。通过以新能源为主体的交直流融合的微电网络，大力推动清洁能源的优先使用。在深度利用以链接和聚合电动汽车电池及梯次利用电池的储能网络的同时，实现削峰填谷，减少高峰时期充电。'
        },
      ],
      showImg: require("../images/cdyy/ggcdw-part-fa-pic1.png"),
      showText: '建设在地面或地下公共停车场，主要设置充电箱变或群充设备。可根据现场电力容量、车辆充电需求灵活调配功率和终端数量，配合两层安全防护体系让爱车充电更安全高效。云平台具有强大引流能力，充分提升资产利用率。完善的智能运维体系有效提升充电场站运营效率'
    };
  },
  methods:{
    handleClickThirdPart(item, index){
        this.thirdPartIndex = index
        this.showImg = item.img
        this.showText = item.text
    }
  }
};
</script>

<style lang="scss" scoped>
.cdyy-jmcdw-container {
  width: 100%;
  background-color: #fff;
  .common-part {
    width: 100%;
    box-sizing: border-box;
    padding: 100px 160px;
    position: relative;
  }
  .first-part {
    min-height: 584px;
    .gs-text {
      width: 1000px;
      font-weight: 400;
      font-size: 18px;
      color: #1d1d1d;
      margin-top: 30px;
      line-height: 32px;
    }
    .gs-pic {
      position: absolute;
      width: 362px;
      height: 584px;
      right: 190px;
      top: 0;
    }
  }
  .second-part {
    width: 100%;
    .second-part-cont {
      width: 100%;
      /* height: 237px; */
      display: flex;
      flex-wrap: wrap;
      .second-part-item {
        width: 218px;
        margin-right: 20px;
        height: 237px;
        margin-bottom: 20px;
        background-image: url("../images/cdyy/jmcd-ys-item-bg.png");
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .second-part-item-pic {
          width: 100%;
          height: 80px;
          text-align: center;
          line-height: 80px;
          margin-bottom: 30px;
          .part-item-pic {
            display: inline-block;
            width: 68px;
            height: 68px;
            margin-top: 10px;
          }
        }
        .second-part-item-cont {
          width: 100%;
          font-size: 18px;
          color: #1d1d1d;
          line-height: 36px;
          .spi-text {
            text-align: center;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .third-part {
    width: 100%;
    .third-part-cont {
      width: 100%;
      display: flex;
      .third-left-part {
        flex: 1;
        height: 618px;
        background-image: url("../images/cdyy/jmcd-fa-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        box-sizing: border-box;
        padding: 70px 80px;
        .left-part-title{
            width: 100%;
            font-weight: 400;
            font-size: 32px;
            color: #FFFFFF;
            line-height: 60px;
            margin-bottom: 30px;
        }
        .left-part-list{
            width: 100%;
            font-size: 18px;
            color: #D4E6FF;
            line-height: 60px;
        }
        .left-part-list-item{
            margin-bottom: 20px;
            position: relative;
            cursor: pointer;
        }
        .left-part-list-item-active{
            color: #FFFFFF;
            &::before{
                content: "";
                position: absolute;
                width: 13px;
                height: 7px;
                background-image: url('../images/cdyy/jmcd-fa-active-arrow.png');
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center;
                vertical-align: middle;
                left: -25px;
                top: 25px;
            }
        }
      }
      .third-right-part {
        flex: 1.2;
        height: 709px;
        background-color: #f6f6f6;
        box-sizing: border-box;
        padding: 20px 80px 0;
        .third-right-part-cont{
            width: 100%;
            height: 100%;
            background-color: #fff;
            box-sizing: border-box;
            padding: 40px;
            .part-cont-show-pic{
                width: 100%;
                height: 322px;
                margin-bottom: 35px;
                .show-img{
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                }
            }
            .part-cont-show-text{
                width: 100%;
                font-size: 18px;
                color: #1D1D1D;
                line-height: 36px;
            }
        }
      }
    }
  }
}
</style>



