<template>
  <div class="sjyy-cwhd-container">
    <div class="common-part first-part">
        <section-title :titleInfo="firstPartTitleInfo"></section-title>
        <div class="gs-cont-text">
          车网互动是指电动汽车与电网进行能量和信息的互动。从能量流向上分为单向互动（充电功率受控的有序充电）和双向互动（既可充电也可放电的双向充放电）。根据电力系统运行需要，适时、适度开展车网互动，灵活调整电动汽车充放电功率、时段，能发挥削峰填谷、阻塞缓解等作用，有助于系统电力实时平衡和电网安全、经济、可靠供电。
        </div>
        <section-title :titleInfo="secondPartTitleInfo"></section-title>
        <div class="target-cont">
            <div class="target-cont-text">
              <div class="text-part">
                实现规模化车网互动，在新型电力系统、电力市场、数字电网等外部环境基础上，由资源聚合商利用商业和技术手段组织电动汽车用户，充分挖掘电动汽车的电力负荷可调节潜力，形成电力电量商品，出售给灵活资源需求体的商业行为。车网互动规模化，是促进新能源发电和新能源汽车产业发展的必然结果，能够带动相关产业新一轮升级，为践行能源安全新战略、实现“双碳”战略目标提供有力支撑。
              </div>
              <div class="img-part">
                <img class="left-img-part" src="../images/sjyy/sjyy-cwhd-part-one.png" alt="">
              </div>
            </div>
            <div class="target-cont-img">
              <img class="target-cont-pic" src="../images/sjyy/sjyy-cwhd-part-two.png" alt="">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: {
    sectionTitle,
  },
  data(){
    return {
        firstPartTitleInfo: {
            bigtext1: "概述",
        },
        secondPartTitleInfo: {
            bigtext1: "目标",
        },
    }

  }
};
</script>

<style lang="scss" scoped>
.sjyy-cwhd-container {
  width: 100%;
  background-color: #fff;
  .common-part {
    width: 100%;
    box-sizing: border-box;
    padding: 100px 160px;
    position: relative;
  }
  .first-part{
    width: 100%;
    /* height: 1080px; */
    background-image: url('../images/nyjh/nyjh-xqxy-section-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    .gs-cont-text{
        width: 1000px;
        font-weight: 400;
        font-size: 18px;
        color: #1D1D1D;
        line-height: 32px;
        margin: 30px 0 200px;
    }
    .target-cont{
        width: 100%;
        display: flex;
        .target-cont-text{
            flex: 1;
            font-weight: 400;
            font-size: 18px;
            color: #1D1D1D;
            line-height: 32px;
            box-sizing: border-box;
            padding-right: 5px;
            .text-part{
              width: 100%;
              box-sizing: border-box;
              padding-right: 30px;
              margin-bottom: 100px;
            }
            .img-part{
              width: 100%;
              height: 618px;
              position: relative;
              .left-img-part{
                position: absolute;
                width: 711px;
                height: 618px;
                top: 0;
                right: 0;
              }
            }

        }
        .target-cont-img{
            width: 879px;
            height: 802px;
            position: relative;
            /* background-image: url('../images/sjyy/sjyy-jnrh-part-bg.png');
            background-repeat: no-repeat;
            background-size: 1172px 553px;
            background-position: right; */
            .target-cont-pic{
              position: absolute;
              right: 0;
              bottom: 0;
              width: 879px;
              height: 802px;
            }
        }

    }
  } 
}
</style>