<template>
  <div class="cljc-container">
    <div class="part1">
      <section-title :titleInfo="firstPartTitleInfo"></section-title>
      <div class="part1Body">
        <div class="bodyLeft">
          <div class="title">原理：</div>
          <div class="text">
            检测系统通过充电安全检测装置,实时获取电动汽车充电数据,利用云端电池检测分析平台完成对充电
          </div>
          <div class="text">
            极值电压、电流、功率、电池一致性、SOC测量精度、BMS
            测量精度等检测项的分析评估并给出故
          </div>
          <div class="text">障预警。</div>
          <div class="title" style="margin-top: 10px">依据：</div>
          <div class="text">DB32／T 4380-2022</div>
          <div class="text">
            《在运电动汽车锂离子动力电池系统技术要求及现场检测方法》
          </div>
          <div class="text">GB 38031-2020</div>
          <div class="text">《电动汽车用动力蓄电池安全要求》</div>
          <div class="text">......</div>
        </div>
        <div class="bodyRight"></div>
      </div>
    </div>
    <div class="part2">
      <section-title :titleInfo="firstPartTitleInfo2"></section-title>
      <div class="bigImage"></div>
      <div class="part2Body">
        <div class="part2bodyLeft">
          <div class="part2bodyLeftDiv1">
            <div class="leftContain">
              <div class="leftIcon1"></div>
              <div class="leftDec">SOH评估</div>
            </div>
            <div class="leftContain">
              <div class="leftIcon2"></div>
              <div class="leftDec">AI预警</div>
            </div>
            <div class="leftContain">
              <div class="leftIcon3"></div>
              <div class="leftDec">多源数据接入</div>
            </div>
          </div>
          <div class="part2bodyLeftDiv1">
            <div class="leftContain">
              <div class="leftIcon4"></div>
              <div class="leftDec">故障诊断</div>
            </div>
            <div class="leftContain">
              <div class="leftIcon5"></div>
              <div class="leftDec">数据分析</div>
            </div>
            <div class="leftContain">
              <div class="leftIcon6"></div>
              <div class="leftDec">检测报告生成</div>
            </div>
          </div>
        </div>
        <div class="part2bodyRight">
          <div class="title1">
            <div class="title1Icon"></div>
            检测内容
          </div>
          <div class="title2">充电温度差</div>
          <div class="dec">测量充电过程中各电池温度变化差值</div>
          <div class="title2">电池一致性</div>
          <div class="dec">反映电池不同电池之间温度、电压、SOC 的一致性</div>
          <div class="title2">SOC 测量精度</div>
          <div class="dec">反映电池充电过程中 SOC 指标的线性度和精确度</div>
          <div class="title2">BMS 测量精度</div>
          <div class="dec">
            充电过程中 BMS 测量值与桩实际输出值之间的误差情况
          </div>
        </div>
      </div>
    </div>
    <div class="part3">
      <section-title :titleInfo="firstPartTitleInfo3"></section-title>
      <div class="derction">
        基于采集的全量数据，结合检测云上积累的大量充电数据进行智能算法分析，用户在充电过程中即可完成电池检测，给出电池健康度分析等专业的电池检测报告。
      </div>
      <div class="imageBody">
        <div class="part3Image"></div>
      </div>
    </div>
    <div class="part4">
      <section-title :titleInfo="firstPartTitleInfo4"></section-title>
      <div class="part4Body">
        <div class="part2Left"></div>
        <div class="part2Right">
          <div class="p2RLeft">
            <div class="city">南京</div>
            <div class="city">南通</div>
            <div class="city">盐城</div>
            <div class="city">徐州</div>
            <div class="city">无锡</div>
            <div class="city">苏州</div>
            <div class="city">连云港</div>
          </div>
          <div class="p2RRight"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: { sectionTitle },
  data() {
    return {
      firstPartTitleInfo: {
        bigtext1: "电池检测原理及依据",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      firstPartTitleInfo2: {
        bigtext1: "电池检测平台",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      firstPartTitleInfo3: {
        bigtext1: "总体方案",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
      firstPartTitleInfo4: {
        bigtext1: "案例介绍",
        bigtext2: "",
        smallText: "",
        showKnowMore: false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.cljc-container {
  width: 100%;
  min-height: 1080px;
  .part1 {
    width: 100%;
    height: 600px;
    box-sizing: border-box;
    padding: 100px 160px;
    .part1Body {
      display: flex;
      width: 100%;
      height: 342px;
      justify-content: space-between;
      .bodyLeft {
        width: 828px;
        height: 100%;
        .title {
          font-weight: bold;
          margin-bottom: 10px;
        }
        .text {
          line-height: 36px;
        }
      }
      .bodyRight {
        width: 719px;
        height: 100%;
        background: url("../images/cljc/cljcPart1.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .part2 {
    width: 100%;
    box-sizing: border-box;
    padding: 100px 160px;
    .bigImage {
      width: 100%;
      height: 264px;
      background: url("../images/cljc/cljcPart2Bg1.png") no-repeat;
      background-size: 100% 100%;
    }
    .part2Body {
      display: flex;
      width: 100%;
      height: 400px;
      background: url("../images/cljc/cljcPart2Bg2.png") no-repeat;
      background-size: 100% 100%;
      .part2bodyLeft {
        width: 51%;
        height: 100%;
        padding-top: 50px;
        box-sizing: border-box;
        .part2bodyLeftDiv1 {
          display: flex;
          justify-content: space-evenly;
          margin-bottom: 20px;
          .leftContain {
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            width: 210px;
            height: 105px;
            background: url("../images/cljc/cljcPart2SB.png") no-repeat;
            background-size: 100% 100%;
            .leftIcon1 {
              width: 38px;
              height: 38px;
              background: url("../images/cljc/cljcPart2SB.png") no-repeat;
              background-size: 100% 100%;
            }
            .leftIcon1 {
              width: 38px;
              height: 38px;
              background: url("../images/cljc/cljcicon1.png") no-repeat;
              background-size: 100% 100%;
            }
            .leftIcon2 {
              width: 38px;
              height: 38px;
              background: url("../images/cljc/cljcicon2.png") no-repeat;
              background-size: 100% 100%;
            }
            .leftIcon3 {
              width: 38px;
              height: 38px;
              background: url("../images/cljc/cljcicon3.png") no-repeat;
              background-size: 100% 100%;
            }
            .leftIcon4 {
              width: 38px;
              height: 38px;
              background: url("../images/cljc/cljcicon4.png") no-repeat;
              background-size: 100% 100%;
            }
            .leftIcon5 {
              width: 38px;
              height: 38px;
              background: url("../images/cljc/cljcicon5.png") no-repeat;
              background-size: 100% 100%;
            }
            .leftIcon6 {
              width: 38px;
              height: 38px;
              background: url("../images/cljc/cljcicon6.png") no-repeat;
              background-size: 100% 100%;
            }
            .leftDec {
              font-size: 20px;
              font-weight: bold;
            }
          }
        }
      }
      .part2bodyRight {
        width: 49%;
        height: 100%;
        padding: 50px;
        box-sizing: border-box;
        .title1 {
          display: flex;
          align-items: center;
          font-size: 20px;
          margin-bottom: 30px;
          .title1Icon {
            width: 5px;
            height: 24px;
            margin-right: 10px;
            background: url("../images/cljc/infoIcon.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        .title2 {
          font-weight: bold;
          margin-bottom: 10px;
        }
        .dec {
          margin-bottom: 15px;
        }
      }
    }
  }
  .part3 {
    width: 100%;
    box-sizing: border-box;
    padding: 100px 160px;
    .derction {
      margin-bottom: 105px;
    }
    .imageBody {
      display: flex;
      justify-content: center;
      width: 100%;
      .part3Image {
        width: 1258px;
        height: 1884px;
        background: url("../images/cljc/part3Image.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .part4 {
    width: 100%;
    height: 1080px;
    box-sizing: border-box;
    padding: 100px 160px;
    background-color: #f6f6f6;
    .part4Body {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .part2Left {
        width: 778px;
        height: 722px;
        background: url("../images/cljc/part4Left.png") no-repeat;
        background-size: 100% 100%;
      }
      .part2Right {
        display: flex;
        justify-content: space-between;
        width: 615px;
        height: 820px;
        .p2RLeft {
          display: flex;
          flex-flow: column;
          padding-left: 160px;
          padding-top: 70px;
          box-sizing: border-box;
          width: 300px;
          height: 100%;
          background: url("../images/cljc/p4RLEft.png") no-repeat;
          background-size: 100% 100%;
          .city {
            margin-bottom: 75px;
          }
        }
        .p2RRight {
          width: 308px;
          height: 100%;
          background: url("../images/cljc/p4RRight.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>

