<template>
  <div class="cdyy-jmcdw-container">
    <div class="common-part first-part">
      <section-title :titleInfo="firstPartTitleInfo"></section-title>
      <div class="gs-text">
        随着新能源汽车的飞速发展，私家车逐渐成为电动汽车增长的主力军，相较于大型公共充电站，小区、园区等驻地类充电站将成为未来充电的重要场景。而随着相关政策的引导以及日益增大的市场需求，在社区、园区、写字楼等地区建设充电配套设施，正成为一个迫切的需求点。智融科技采取“统建统营
        有序充电”模式，构建面向新能源汽车超大规模接入的智慧充电网，满足社区居民“在家充电”需求，提供安全、智能、便捷、经济、可持续的充电服务。
      </div>
      <img class="gs-pic" src="../images/cdyy/gs-pic.png" alt="" />
    </div>

    <div class="common-part second-part">
      <section-title :titleInfo="secondPartTitleInfo"></section-title>

      <div class="second-part-cont">
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/jmcd-ys-logo1.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">统建统营</div>
            <div class="spi-text">两级调控</div>
            <div class="spi-text">节省增容费用</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/jmcd-ys-logo2.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">负荷调度</div>
            <div class="spi-text">有序充电</div>
            <div class="spi-text">保障电网安全</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/jmcd-ys-logo3.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">主动防护</div>
            <div class="spi-text">电池检测</div>
            <div class="spi-text">保障充电安全</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/jmcd-ys-logo4.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">智能运维</div>
            <div class="spi-text">定期巡检</div>
            <div class="spi-text">保障运行稳定</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/jmcd-ys-logo5.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">统建精细运营</div>
            <div class="spi-text">24h客服</div>
            <div class="spi-text">提供可靠服务统营</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/jmcd-ys-logo6.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">应急处置</div>
            <div class="spi-text">安全保险</div>
            <div class="spi-text">充电保驾护航</div>
          </div>
        </div>
        <div class="second-part-item">
          <div class="second-part-item-pic">
            <img
              class="part-item-pic"
              src="../images/cdyy/jmcd-ys-logo7.png"
              alt=""
            />
          </div>
          <div class="second-part-item-cont">
            <div class="spi-text">车网互动</div>
            <div class="spi-text">移动储能</div>
            <div class="spi-text">响应电网需求</div>
          </div>
        </div>
      </div>
    </div>

    <div class="common-part third-part">
      <section-title :titleInfo="thirdPartTitleInfo"></section-title>

      <div class="third-part-cont">
        <div class="third-left-part">
            <div class="left-part-title">
                居民小区
            </div>
            <div class="left-part-list">
                <div class="left-part-list-item" :class="{'left-part-list-item-active': thirdPartIndex == index}" v-for="(item, index) in leftPartList" :key="index" @click="handleClickThirdPart(item, index)">
                    {{ item.label }}
                </div>
            </div>
        </div>
        <div class="third-right-part">
            <div class="third-right-part-cont">
                <div class="part-cont-show-pic">
                    <img class="show-img" :src="showImg" alt="">
                </div>
                <div class="part-cont-show-text">
                    {{ showText }}
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: {
    sectionTitle,
  },
  data() {
    return {
      firstPartTitleInfo: {
        bigtext1: "概述",
      },
      secondPartTitleInfo: {
        bigtext1: "我们在小区充电领域的优势",
      },
      thirdPartTitleInfo: {
        bigtext1: "居民小区充电解决方案",
      },
      thirdPartIndex:0,
      leftPartList:[
        {
            label:'新建小区公用桩（公共车位）',
            img: require("../images/cdyy/jmcd-part-fa-pic1.png"),
            text:'新建小区按政府要求配建一定比例的充电设施，智融提供优质设备和建设安装，满足建设单位验收需求。并提供充电设施运维管理服务，保障充电设施安全稳定运行。建管一体，放心无忧。'
        },
        {
            label:'既有小区公用桩（公共车位）',
            img: require("../images/cdyy/jmcd-part-fa-pic1.png"),
            text:'统一规划建设、统一维护管理居民小区充电设施，解决车主个人无序建桩、无序充电造成的安全问题。针对老旧小区电力配套不足、没有产权车位导致的建桩难题，免费建设公用桩，解决车主充电焦虑；应用智能有序充电技术，降低电力增容改造费用，满足大规模电动汽车充电需求。'
        },
        {
            label:'既有小区自用桩（固定车位）',
            img: require("../images/cdyy/jmcd-part-fa-pic1.png"),
            text:'按照“统建统管”模式开展干线电缆等供电设施改造，提供自用桩安装与维护管理一体化服务，解决个人无序建桩、无序充电问题，车主和物业省心又安心。采用“两层调度、有序充电”技术，保障电网和充电安全，满足大规模电动汽车充电需求。前瞻性的采用小功率直流设备，为构建新型电力系统打造基础。'
        },
        {
            label:'小区周边公共站',
            img: require("../images/cdyy/jmcd-part-fa-pic1.png"),
            text:'对于充电需求旺盛、但小区内部不具备建桩条件，在小区周边建设公共充电站，快慢充结合，满足各类车主“就近充电”需求，探索“代充”模式，提供便捷服务。'
        },
      ],
      showImg: require("../images/cdyy/jmcd-part-fa-pic1.png"),
      showText: '新建小区按政府要求配建一定比例的充电设施，智融提供优质设备和建设安装，满足建设单位验收需求。并提供充电设施运维管理服务，保障充电设施安全稳定运行。建管一体，放心无忧。'
    };
  },
  methods:{
    handleClickThirdPart(item, index){
        this.thirdPartIndex = index
        this.showImg = item.img
        this.showText = item.text
    }
  }
};
</script>

<style lang="scss" scoped>
.cdyy-jmcdw-container {
  width: 100%;
  background-color: #fff;
  .common-part {
    width: 100%;
    box-sizing: border-box;
    padding: 100px 160px;
    position: relative;
  }
  .first-part {
    min-height: 584px;
    .gs-text {
      width: 1000px;
      font-weight: 400;
      font-size: 18px;
      color: #1d1d1d;
      margin-top: 30px;
      line-height: 32px;
    }
    .gs-pic {
      position: absolute;
      width: 362px;
      height: 584px;
      right: 190px;
      top: 0;
    }
  }
  .second-part {
    width: 100%;
    .second-part-cont {
      width: 100%;
      height: 237px;
      display: flex;
      justify-content: space-between;
      .second-part-item {
        flex: 1;
        margin-right: 20px;
        height: 237px;
        background-image: url("../images/cdyy/jmcd-ys-item-bg.png");
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .second-part-item-pic {
          width: 100%;
          height: 80px;
          text-align: center;
          line-height: 80px;
          margin-bottom: 30px;
          .part-item-pic {
            display: inline-block;
            width: 68px;
            height: 68px;
            margin-top: 10px;
          }
        }
        .second-part-item-cont {
          width: 100%;
          font-size: 18px;
          color: #1d1d1d;
          line-height: 36px;
          .spi-text {
            text-align: center;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .third-part {
    width: 100%;
    .third-part-cont {
      width: 100%;
      display: flex;
      .third-left-part {
        flex: 1;
        height: 618px;
        background-image: url("../images/cdyy/jmcd-fa-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        box-sizing: border-box;
        padding: 70px 80px;
        .left-part-title{
            width: 100%;
            font-weight: 400;
            font-size: 32px;
            color: #FFFFFF;
            line-height: 60px;
            margin-bottom: 30px;
        }
        .left-part-list{
            width: 100%;
            font-size: 18px;
            color: #D4E6FF;
            line-height: 60px;
        }
        .left-part-list-item{
            margin-bottom: 20px;
            position: relative;
            cursor: pointer;
        }
        .left-part-list-item-active{
            color: #FFFFFF;
            &::before{
                content: "";
                position: absolute;
                width: 13px;
                height: 7px;
                background-image: url('../images/cdyy/jmcd-fa-active-arrow.png');
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center;
                vertical-align: middle;
                left: -25px;
                top: 25px;
            }
        }
      }
      .third-right-part {
        flex: 1.2;
        height: 709px;
        background-color: #f6f6f6;
        box-sizing: border-box;
        padding: 20px 80px 0;
        .third-right-part-cont{
            width: 100%;
            height: 100%;
            background-color: #fff;
            box-sizing: border-box;
            padding: 40px;
            .part-cont-show-pic{
                width: 100%;
                height: 322px;
                margin-bottom: 35px;
                .show-img{
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                }
            }
            .part-cont-show-text{
                width: 100%;
                font-size: 18px;
                color: #1D1D1D;
                line-height: 36px;
            }
        }
      }
    }
  }
}
</style>



