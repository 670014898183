<template>
  <div>
    <Nav></Nav>
    <banner-part :bannerInfo="nowBannerInfo"></banner-part>
    <div class="container">
      <tabs-section :tabsList="tabsList" :defaultIndex="defaultIndex" v-if="$route.params.index != '4'" @changeTabValueAction="handleChangeTabValue"></tabs-section>
      
      <!-- 充电运营 -->
      <!-- 多元智慧微网 -->
      <cdyy-dyzhww v-if="navTabIndex == '1' && defaultIndex == '1'"></cdyy-dyzhww>
      <!-- 居民小区充电网 -->
      <cdyy-jmcdw v-if="navTabIndex == '1' && defaultIndex == '2'"></cdyy-jmcdw>
      <!-- 公共充电网 -->
      <cdyy-ggcdw v-if="navTabIndex == '1' && defaultIndex == '3'"></cdyy-ggcdw>
      <!-- 公路充电网 -->
      <cdyy-glcdw v-if="navTabIndex == '1' && defaultIndex == '4'"></cdyy-glcdw>

      <!-- 能源聚合 -->
      <!-- 需求响应 -->
      <nyjh-xqxy v-if="navTabIndex == '2' && defaultIndex == '1'"></nyjh-xqxy>
      <!-- 微网管理 -->
      <nyjh-wwgl v-if="navTabIndex == '2' && defaultIndex == '2'"></nyjh-wwgl>

      <!-- 数据运营 -->
      <!-- 车网互动 -->
      <sjyy-cwhd v-if="navTabIndex == '3' && defaultIndex == '1'"></sjyy-cwhd>
      <!-- 交能融合 -->
      <sjyy-jnrh v-if="navTabIndex == '3' && defaultIndex == '2'"></sjyy-jnrh>

      <!-- 车辆检测 -->
      <cljc v-if="navTabIndex == '4'"></cljc>

    </div>
    <Footer class="top" />
  </div>
</template>

<script>
import BannerPart from "../components/BannerPart.vue";
import CdyyDyzhww from './cpns/cdyyDyzhww.vue';
import CdyyGgcdw from './cpns/cdyyGgcdw.vue';
import CdyyGlcdw from './cpns/cdyyGlcdw.vue';
import CdyyJmcdw from './cpns/cdyyJmcdw.vue';
import Cljc from './cpns/cljc.vue';
import NyjhWwgl from './cpns/nyjhWwgl.vue';
import NyjhXqxy from './cpns/nyjhXqxy.vue';
import SjyyCwhd from './cpns/sjyyCwhd.vue';
import SjyyJnrh from './cpns/sjyyJnrh.vue';
import TabsSection from './cpns/zhTabsSection.vue';
export default {
  components: { BannerPart, TabsSection, CdyyDyzhww, CdyyGgcdw, CdyyGlcdw, CdyyJmcdw, NyjhXqxy, NyjhWwgl, SjyyCwhd, SjyyJnrh, Cljc },
  data() {
    return {
      bannerInfo: {
        text1: "智慧运维",
        text2: "智慧运维",
      },
      tabsListInfo:{
        '1':[
          {
            value: "1",
            label: "多元智慧微网",
          },
          {
            value: "2",
            label: "居民小区充电网",
          },
          {
            value: "3",
            label: "公共充电网",
          },
          {
            value: "4",
            label: "公路充电网",
          },
        ],
        '2':[
          {
            value: "1",
            label: "需求响应",
          },
          {
            value: "2",
            label: "微网管理",
          },
        ],
        '3':[
          {
            value: "1",
            label: "车网互动",
          },
          {
            value: "2",
            label: "交能融合",
          },
        ]
      },
      defaultIndex:'1',
    
      bannerInfoMap:{
        '1-1':{
          text1: "多元智慧微网",
          text2: "充电解决方案",
          swiperImg: require("./images/cdyy/dyzhww-banner-pic.png")
        },

        '1-2':{
          text1: "居民小区充电网",
          text2: "充电解决方案",
          swiperImg: require("./images/cdyy/jmcd-banner-pic.png")
        },

        '1-3':{
          text1: "公共充电网",
          text2: "充电解决方案",
          swiperImg: require("./images/cdyy/ggcdw-banner-pic.png")
        },

        '1-4':{
          text1: "公路充电网",
          text2: "充电解决方案",
          swiperImg: require("./images/cdyy/glcdw-banner-pic.png")
        },

        '2-1':{
          text1: "需求响应",
          text2: "做国内一流的智慧能源解决方案及服务提供商",
          swiperImg: require("./images/cdyy/nyjh-xqxy-banner-pic.png")
        },
        '2-2':{
          text1: "微网管理",
          text2: "做国内一流的智慧能源解决方案及服务提供商",
          swiperImg: require("./images/cdyy/nyjh-wwgl-banner-pic.png")
        },

        '3-1':{
          text1: "车网互动",
          text2: "做国内一流的智慧能源解决方案及服务提供商",
          swiperImg: require("./images/cdyy/sjyy-cwhd-banner-pic.png")
        },

        '3-2':{
          text1: "交能融合",
          text2: "做国内一流的智慧能源解决方案及服务提供商",
          swiperImg: require("./images/cdyy/sjyy-jnrh-banner-pic.png")
        },

        '4':{
          text1: "车辆检测",
          text2: "动力电池充电安全检测",
          swiperImg: require("./images/cdyy/cljc-banner-pic.png")
        },
      }
    };
  },
  watch:{
    $route() {
      const { defaultIndex } = this.$route.query
      console.log("defaultIndex>>>", defaultIndex, this.$route)
      this.defaultIndex = defaultIndex || '1'
    },
  },
  mounted(){
    const { defaultIndex } = this.$route.query
    console.log("defaultIndex>>>", defaultIndex, this.$route)
    this.defaultIndex = defaultIndex || '1'
  },

  computed:{
    tabsList(){
      return this.tabsListInfo[this.$route.params.index || '1']
    },
    navTabIndex(){
      return this.$route.params.index || '1'
    },
    nowBannerInfo(){
      let bannerInfo = {}
      if(this.navTabIndex == '4'){
        bannerInfo = this.bannerInfoMap[this.navTabIndex]
      } else {
        bannerInfo = this.bannerInfoMap[this.navTabIndex + '-' + this.defaultIndex]
      }
      return bannerInfo
    }
  },

  methods:{
    handleChangeTabValue(value){
      this.defaultIndex = value
    }
  }

};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 600px;
}
</style>