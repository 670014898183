<template>
  <div class="nyjh-wwgl-container">
    <div class="common-part first-part">
        <section-title :titleInfo="firstPartTitleInfo"></section-title>
        <div class="gs-cont-text">
            智融能源科技深耕电力物联网领域，倾力打造“云、网、边、端”一体化的微网管家解决方案。秉承“软件APP化、硬件模块化”的创新设计理念，我们的边端设备与云端平台无缝对接。智慧微电网的革新者，集光伏监控、储能调控与负荷管理于一体。依托先进算法与实时数据分析，精准预测与优化能源使用，实现光伏效能最大化，储能灵活调配，及用户需求智能响应，赋能绿色转型，引领高效、可持续的能源未来。形成灵活多变的部署模式，满足不同场景下的个性化需求。
        </div>
        <section-title :titleInfo="secondPartTitleInfo"></section-title>
        <div class="target-cont">
            <div class="target-cont-text">
                <div class="cont-text1">
                    “云、网、边、端”无缝融合
                </div>
                <div class="cont-text-item">
                    <div class="cont-text-item-title">
                        云端智能中心
                    </div>
                    <div class="cont-text-item-value">
                        集数据分析、决策支持与远程监控于一体，为系统提供全局视角与智能策略。
                    </div>
                </div>
                <div class="cont-text-item">
                    <div class="cont-text-item-title">
                        网络层
                    </div>
                    <div class="cont-text-item-value">
                        依托双模通信技术，确保数据传输的安全与高效，构建稳定可靠的物联网通信网络。
                    </div>
                </div>
                <div class="cont-text-item">
                    <div class="cont-text-item-title">
                        边缘计算层
                    </div>
                    <div class="cont-text-item-value">
                        部署于“边”侧的智能处理单元，实现数据预处理与即时响应，减轻云端负担，加速决策流程。
                    </div>
                </div>
                <div class="cont-text-item">
                    <div class="cont-text-item-title">
                        终端设备层
                    </div>
                    <div class="cont-text-item-value">
                        涵盖智能光伏监控器、储能管理系统、负荷管理终端及各种传感器，直接采集现场数据，执行指令。
                    </div>
                </div>
            </div>
            <div class="target-cont-img">
                <img class="cont-img-pic" src="../images/nyjh/nyjh-wwgl-part-bg.png" alt="">

            </div>
        </div>
    </div>
    <div class="common-part second-part">
        <section-title :titleInfo="thirdPartTitleInfo"></section-title>
        <div class="fa-cont-list">
            <div class="fa-cont-item">
                <div class="fa-cont-pic">
                    <img src="../images/nyjh/nyjh-fa-logo1.png" alt="">
                </div>
                <div class="fa-cont-text">
                    “云、网、边、端”一体化架构
                </div>
            </div>
            <div class="fa-cont-item">
                <div class="fa-cont-pic">
                    <img src="../images/nyjh/nyjh-fa-logo2.png" alt="">
                </div>
                <div class="fa-cont-text">
                    软件APP化与硬件模块化设计
                </div>
            </div>
            <div class="fa-cont-item">
                <div class="fa-cont-pic">
                    <img src="../images/nyjh/nyjh-fa-logo3.png" alt="">
                </div>
                <div class="fa-cont-text">
                    智能光伏监控与优化
                </div>
            </div>
            <div class="fa-cont-item">
                <div class="fa-cont-pic">
                    <img src="../images/nyjh/nyjh-fa-logo4.png" alt="">
                </div>
                <div class="fa-cont-text">
                    储能系统智能调控
                </div>
            </div>
            <div class="fa-cont-item">
                <div class="fa-cont-pic">
                    <img src="../images/nyjh/nyjh-fa-logo5.png" alt="">
                </div>
                <div class="fa-cont-text">
                    精细化负荷管理
                </div>
            </div>

            <div class="fa-cont-item">
                <div class="fa-cont-pic">
                    <img src="../images/nyjh/nyjh-fa-logo6.png" alt="">
                </div>
                <div class="fa-cont-text">
                    故障预警与主动运维
                </div>
            </div>
            <div class="fa-cont-item">
                <div class="fa-cont-pic">
                    <img src="../images/nyjh/nyjh-fa-logo7.png" alt="">
                </div>
                <div class="fa-cont-text">
                    用户友好界面与定制服务
                </div>
            </div>

        </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: {
    sectionTitle,
  },
  data(){
    return {
        firstPartTitleInfo: {
            bigtext1: "概述",
        },
        secondPartTitleInfo: {
            bigtext1: "核心架构",
        },
        thirdPartTitleInfo: {
            bigtext1: "方案亮点",
        },
    }

  }
};
</script>

<style lang="scss" scoped>
.nyjh-wwgl-container {
  width: 100%;
  background-color: #fff;
  .common-part {
    width: 100%;
    box-sizing: border-box;
    padding: 100px 160px;
    position: relative;
  }
  .first-part{
    width: 100%;
    height: 1080px;
    background-image: url('../images/nyjh/nyjh-xqxy-section-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    .gs-cont-text{
        width: 1000px;
        font-weight: 400;
        font-size: 18px;
        color: #1D1D1D;
        line-height: 32px;
        margin: 30px 0 150px;
    }
    .target-cont{
        width: 100%;
        position: relative;
        height: 400px;
        display: flex;
        .target-cont-text{
            flex: 1;
            box-sizing: border-box;
            font-weight: 400;
            font-size: 18px;
            color: #1D1D1D;
            line-height: 32px;
            padding-right: 20px;
            .cont-text1{
                color: #1F63EA;
                font-size: 18px;
                margin-bottom: 20px;
            }
            .cont-text-item{
                margin-bottom: 20px;
                .cont-text-item-title{
                    font-weight: bold;
                }
            }
        }
        .target-cont-img{
            position: relative;
            width: 800px;
            height: 373px;
            .cont-img-pic{
                position: absolute;
                top: 30px;
                right: -170px;
                width: 968px;
                height: 373px;
            }
        }

    }
  } 
  .second-part{
    .fa-cont-list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .fa-cont-item{
            flex-basis: 23%;
            height: 163px;
            margin-right: 20px;
            margin-bottom: 10px;
            background-image: url('../images/nyjh/nyjh-fald-item-bg.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            box-sizing: border-box;
            padding: 30px;
            .fa-cont-pic{
                width: 100%;
                height: 48px;
                text-align: center;
                .fa-cont-pic{
                    display: inline-block;
                    width: 48px;
                    height: 48px;
                }
            }
            .fa-cont-text{
                width: 100%;
                height: 60px;
                text-align: center;
                font-size: 18px;
                color: #1D1D1D;
                line-height: 60px;
            }


        }

    }

  }
}
</style>