<template>
  <div class="nyjh-xqxy-container">
    <div class="common-part first-part">
        <section-title :titleInfo="firstPartTitleInfo"></section-title>
        <div class="gs-cont-text">
            依托虚拟电厂，构建电力用户、负荷聚合商参与电力需求响应的市场机制，运用经济杠杆引导各类市场主体灵活调节、多向互动，提升用户负荷管理能力，确保电网运行稳定可靠。
        </div>
        <section-title :titleInfo="secondPartTitleInfo"></section-title>
        <div class="target-cont">
            <div class="target-cont-text">
                通过发动各类用户参与电力需求响应，实现电力削峰填谷，促进可再生能源消纳，推动源网荷储友好协同调节。建立日前、日内和实时响应相结合的模式，构建场景丰富、资源精准的需求响应体系，适用各类负荷缺口情况，有效提升电力系统灵活性。
            </div>
            <div class="target-cont-img">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: {
    sectionTitle,
  },
  data(){
    return {
        firstPartTitleInfo: {
            bigtext1: "概述",
        },
        secondPartTitleInfo: {
            bigtext1: "目标",
        },
    }

  }
};
</script>

<style lang="scss" scoped>
.nyjh-xqxy-container {
  width: 100%;
  background-color: #fff;
  .common-part {
    width: 100%;
    box-sizing: border-box;
    padding: 100px 160px;
    position: relative;
  }
  .first-part{
    width: 100%;
    height: 1080px;
    background-image: url('../images/nyjh/nyjh-xqxy-section-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    .gs-cont-text{
        width: 1000px;
        font-weight: 400;
        font-size: 18px;
        color: #1D1D1D;
        line-height: 32px;
        margin: 30px 0 150px;
    }
    .target-cont{
        width: 100%;
        display: flex;
        .target-cont-text{
            flex: 1;
            box-sizing: border-box;
            padding-right: 30px;
            font-weight: 400;
            font-size: 18px;
            color: #1D1D1D;
            line-height: 32px;
        }
        .target-cont-img{
            width: 654px;
            height: 398px;
            background-image: url('../images/nyjh/nyjh-xqxy-part-bg.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
        }

    }
  } 
}
</style>