<template>
  <div class="tabs-section">
    <div
      class="tab-item"
      :class="{ 'item-active': tabValue == item.value }"
      v-for="(item, index) in tabsList"
      :key="index"
      @click="handleClickItem(item)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    defaultIndex: {
      type: String,
      default: "1",
    },
    tabsList: {
      type: Array,
      default: () => [
        {
          value: "1",
          label: "标签一",
        },
        {
          value: "2",
          label: "标签二",
        },
        {
          value: "3",
          label: "标签三",
        },
      ],
    },
  },
  watch: {
    defaultIndex() {
      console.log("监听到defaultIndex>>>", this.defaultIndex);
      this.tabValue = this.defaultIndex;
    },
  },
  data() {
    return {
      tabValue: "1",
    };
  },
  mounted(){
    this.tabValue = this.defaultIndex
  },
  methods: {
    handleClickItem(item) {
      this.tabValue = item.value;
      this.$emit('changeTabValueAction', this.tabValue)
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-section {
  width: 100%;
  height: 98px;
  box-sizing: border-box;
  padding: 0 100px;
  background-color: #fff;
  display: flex;
  .tab-item {
    width: 230px;
    height: 98px;
    line-height: 98px;
    text-align: center;
    font-size: 22px;
    color: #1d1d1d;
    cursor: pointer;
  }
  .item-active {
    background-color: #1f63ea;
    color: #fff;
  }
}
</style>