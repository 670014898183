<template>
  <div class="cdyy-dyzhww-container">
    <div class="common-part first-part">
      <section-title :titleInfo="firstPartTitleInfo"></section-title>
      <div class="gs-text">
        对电网公司来说可实现电力需求的削峰填谷，促进电力供应与需求的平衡稳定，确保电网的高效安全运行。对运营商来说能提高绿色能源的利用率，推动资产向绿色低碳转型，助力国家实现碳达峰、碳中和目标。同时通过积极参与电力需求响应，提升运营效益。车主也可以享受更为经济的充电成本，同时，充分发掘和利用电动汽车作为移动储能单元的特性，为车主创造额外的收益机会。
      </div>
      <img class="gs-pic" src="../images/cdyy/gs-pic.png" alt="" />
    </div>

    <div class="common-part second-part">
      <section-title :titleInfo="secondPartTitleInfo"></section-title>
      <div class="second-part-list">
        <div class="second-part-item">
            <div class="sp-item-title">
                <img class="sp-item-title-img" src="../images/cdyy/xtdd-title1.png" alt="">
            </div>
            <div class="sp-item-cont">
                实时全面掌握能源运行情况，及时发现异常，提升运行效率
            </div>
        </div>
        <div class="second-part-item">
            <div class="sp-item-title">
                <img class="sp-item-title-img" src="../images/cdyy/xtdd-title2.png" alt="">
            </div>
            <div class="sp-item-cont">
                通过能源平衡分析，及时发现计量异常及跑、冒、滴、漏问题
            </div>
        </div>
        <div class="second-part-item">
            <div class="sp-item-title">
                <img class="sp-item-title-img" src="../images/cdyy/xtdd-title3.png" alt="">
            </div>
            <div class="sp-item-cont">
                综合考虑各类能源成本，合理安排产能、储能、用能计划，使得系统整体运行经济性最高
            </div>
        </div>
        <div class="second-part-item">
            <div class="sp-item-title">
                <img class="sp-item-title-img" src="../images/cdyy/xtdd-title4.png" alt="">
            </div>
            <div class="sp-item-cont">
                对电动汽车动力电池进行安全检测，整个过程全自动，无感知
            </div>
        </div>
        <div class="second-part-item">
            <div class="sp-item-title">
                <img class="sp-item-title-img" src="../images/cdyy/xtdd-title5.png" alt="">
            </div>
            <div class="sp-item-cont">
                根据电网需求和价格信号，自动调整能源的释放或存储，参与需求侧响应，帮助平衡电网负荷
            </div>
        </div>
        <div class="second-part-item">
            <div class="sp-item-title">
                <img class="sp-item-title-img" src="../images/cdyy/xtdd-title6.png" alt="">
            </div>
            <div class="sp-item-cont">
                自动进行能源买卖决策，根据市场电价波动、电力需求和光伏发电预测、储能系统状态，实时调整充电站运行策略，无需人工持续监控
            </div>
        </div>
      </div>
    </div>

    <div class="common-part third-part">
        <section-title :titleInfo="thirdPartTitleInfo"></section-title>
        <div class="third-part-cont">
            <div class="third-part-cont-text third-part-cont-text1">
                根据实时电价数据、电网运行数据、中长期交易数据、需求响应交易信息、可调节能力、申报价格等信息
            </div>
            <div class="third-part-cont-text third-part-cont-text2">
                虚拟电厂交易决策中心做出决策，调整光伏出力、储能充放电、充电价格
            </div>
            <div class="third-part-cont-text third-part-cont-text3">
                聚合平台调度光储充资源，同时充电运营平台发布临时充电套餐，推送给电动汽车
            </div>
            <div class="third-part-cont-text third-part-cont-text4">
                充电场站动态结合光伏出力和充电情况调整储能充放电，保障系统稳定和收益最大化
            </div>
        </div>
    </div>

    <div class="common-part fourth-part">
        <section-title :titleInfo="fourthPartTitleInfo"></section-title>
        <div class="fourth-part-cont">
            <div class="fourth-part-cont-item fourth-part-cont-item1">
                <div class="cont-item-title">
                    数据服务
                </div>
                <div class="cont-item-text">
                    提供电池健康状态监测、充电行为分析等增值服务，帮助优化充电策略和电池维护，创造数据增值收益。
                </div>
            </div>
            <div class="fourth-part-cont-item fourth-part-cont-item2">
                <div class="cont-item-title">
                    电力交易
                </div>
                <div class="cont-item-text">
                    利用储能和光伏发电在电力市场中低买高卖，赚取差价利润，优化能源成本。
                </div>
            </div>
            <div class="fourth-part-cont-item fourth-part-cont-item3">
                <div class="cont-item-title">
                    充电服务
                </div>
                <div class="cont-item-text">
                    直接向电动汽车用户提供充电服务，赚取充电费用，提高站点利用率和用户粘性。
                </div>
            </div>
            <div class="fourth-part-cont-item fourth-part-cont-item4">
                <div class="cont-item-title">
                    碳交易
                </div>
                <div class="cont-item-text">
                    通过减少碳排放量获得碳积分或碳信用，出售给需要抵消碳排放的企业，实现额外收益。
                </div>
            </div>
            <div class="fourth-part-cont-item fourth-part-cont-item5">
                <div class="cont-item-title">
                    电力辅助服务
                </div>
                <div class="cont-item-text">
                    参与电网需求响应、调峰填谷、频率调节等辅助服务，从电网公司获取经济补偿。
                </div>
            </div>
        </div>
    </div>

    <div class="common-part five-part">
        <section-title :titleInfo="fivePartTitleInfo"></section-title>
        <div class="five-part-cont">
            <div class="five-part-cont-item five-part-cont-item1">
                <div class="cont-item-text">
                    <div class="cont-item-text-title">
                        风力发电系统
                    </div>
                    <div class="cont-item-text-info">
                        在高速公路充电站推广应用发电功率为10千瓦的小型风力发电机。利用风力带动风机叶片旋转，再通过调速机构进行风速控制，促使发电机发电。依据现有技术，大约3m/s的微风速度便可开始发电。
                    </div>
                </div>
            </div>
            <div class="five-part-cont-item five-part-cont-item2">
                <div class="cont-item-text">
                    <div class="cont-item-text-title">
                        光伏发电系统
                    </div>
                    <div class="cont-item-text-info">
                        利用车棚建设光伏系统，数据通过微网控制器接入站网互动系统，实现站内光、储、充协同控制。
                    </div>
                </div>
            </div>
            
            <div class="five-part-cont-item five-part-cont-item3">
                <div class="cont-item-text">
                    <div class="cont-item-text-title">
                        放电系统
                    </div>
                    <div class="cont-item-text-info">
                        在高速公路充电站部署V2G充电桩，实现能量的双向传输，灵活地参与电力系统的运营和管理，在满足电动汽车的充电需求的同时，实现电动汽车与各类电源、负荷的智能互动，整体提升区域内配电系统的资源优化配置能力。
                    </div>
                </div>
            </div>
            <div class="five-part-cont-item five-part-cont-item4">
                <div class="cont-item-text">
                    <div class="cont-item-text-title">
                        充电系统
                    </div>
                    <div class="cont-item-text-info">
                        【直流充电机】采用DC-DC模块，可直接接入示范站内直流微网，为站内商用车与乘用车提供直流快速充电服务。【交流有序充电桩】满足电动汽车的交流补电需求的同时，支撑电网实现有序充电，提升电网运行安全性。【液冷超充】利用高效液体冷却系统，为电动汽车提供极速且稳定的充电体验，有效控温，保护电池安全。
                    </div>
                </div>
            </div>
            <div class="five-part-cont-item five-part-cont-item5">
                <div class="cont-item-text">
                    <div class="cont-item-text-title">
                        储能系统
                    </div>
                    <div class="cont-item-text-info">
                        采用一体化储能仓设计，仓内集成磷酸铁锂电池系统、直流变换系统、配电系统、温控系统、消防系统等。储能系统信息数据接入微网控制器，接受站网互动系统的统一调控，实现光、储、充、放的协同互动控制。
                    </div>
                </div>
            </div>

            <div class="five-part-cont-item five-part-cont-item6">
                <div class="cont-item-text">
                    <div class="cont-item-text-title">
                        换电站
                    </div>
                    <div class="cont-item-text-info">
                        在高速公路充电站配置换电站，为电动汽车的动力电池提供快速更换服务，满足车辆换电需求；换电站采用三工位协同作业机制，3分钟完成换电，缩短排队时间。根据蔚来第三代换电站数据，电池仓位可增加到21个，日服务次数可达408次。
                    </div>
                </div>
            </div>
            <div class="five-part-cont-item five-part-cont-item7">
                <div class="cont-item-text">
                    <div class="cont-item-text-title">
                        电动汽车动力电池检测系统
                    </div>
                    <div class="cont-item-text-info">
                        业务功能包括：检测业务管理、车辆健康档案、检测标准管理、电池包老化曲线分析等。核心技术创新点：车辆电池包老化曲线趋势分析（算法模型）、车型电池包特征指标数据分析（大数据）、车辆BMS输入输出算法优化（设备智能）。
                    </div>
                </div>
            </div>
            <div class="five-part-cont-item five-part-cont-item8">
                <div class="cont-item-text">
                    <div class="cont-item-text-title">
                        能源聚合平台
                    </div>
                    <div class="cont-item-text-info">
                        向上对接省市级虚拟电厂平台以及电力交易平台，向下聚合绿色微电网，不仅可以为中小负荷提供参与市场调节的机会，还可以充分发掘负荷资源，提供辅助服务，以数字化手段助力新型电力系统建设和新型能源体系构建。
                    </div>
                </div>
            </div>

        </div>


    </div>

  </div>
</template>

<script>
import sectionTitle from "../../components/section-title.vue";
export default {
  components: { sectionTitle },
  data() {
    return {
      firstPartTitleInfo: {
        bigtext1: "概述",
      },
      secondPartTitleInfo: {
        bigtext1: "系统特点",
      },
      thirdPartTitleInfo:{
        bigtext1: "系统架构",
      },
      fourthPartTitleInfo:{
        bigtext1: "运营模式",
      },
      fivePartTitleInfo:{
        bigtext1: '子系统'
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.cdyy-dyzhww-container {
  width: 100%;
  background-color: #fff;
  .common-part {
    width: 100%;
    box-sizing: border-box;
    padding: 100px 160px;
    position: relative;
  }

  .first-part {
    min-height: 584px;
    .gs-text {
      width: 1000px;
      font-weight: 400;
      font-size: 18px;
      color: #1d1d1d;
      margin-top: 30px;
      line-height: 32px;
    }
    .gs-pic {
      position: absolute;
      width: 362px;
      height: 584px;
      right: 190px;
      top: 0;
    }
  }
  .second-part{
    .second-part-list{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        .second-part-item{
            flex: 1;
            margin-right: 16px;
            height: 332px;
            box-sizing: border-box;
            padding: 0 23px;
            background-image: url('../images/cdyy/xtdd-item-bg.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            .sp-item-title{
                width: 100%;
                height: 64px;
                text-align: center;
                line-height: 64px;
                margin-bottom: 10px;
                .sp-item-title-pic{
                    display: inline-block;
                    width: 87px;
                    height: 21px;
                }
            }
            .sp-item-cont{
                width: 100%;
                font-weight: 400;
                font-size: 18px;
                color: #1D1D1D;
                line-height: 36px;
            }
        }
    }
  }
  .third-part{
    width: 100%;
    height: 1219px;
    background: linear-gradient( 180deg, #FFFFFF 0%, #1F63EA 100%);
    .third-part-cont{
        position: absolute;
        width: 1452px;
        height: 876px;
        top: 260px;
        left: 50%;
        transform: translateX(-726px);
        background-image: url('../images/cdyy/xtjg-part-bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        .third-part-cont-text{
            position: absolute;
            width: 388px;
            left: 30px;
            font-size: 18px;
            color: #1D1D1D;
            line-height: 36px;
        }
        .third-part-cont-text1{
            top: 68px;
        }
        .third-part-cont-text2{
            top: 308px;
        }
        .third-part-cont-text3{
            top: 496px;
        }
        .third-part-cont-text4{
            top: 723px;
        }

    }
  }
  .fourth-part{
    width: 100%;
    height: 1080px;
    background: linear-gradient( 180deg, #D4E6FF 0%, #FFFFFF 100%);
    .fourth-part-cont{
        position: absolute;
        width: 1330px;
        height: 660px;
        top: 318px;
        left: 50%;
        transform: translateX(-665px);
        background-image: url('../images/cdyy/yyms-part-bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        .fourth-part-cont-item{
            position: absolute;
            width: 262px;
            height: 180px;
            .cont-item-title{
                width: 100%;
                height: 30px;
                font-weight: 400;
                font-size: 22px;
                text-align: center;
                color: #1F63EA;
                margin-bottom: 40px;
            }
            .cont-item-text{
                width: 100%;
                font-size: 18px;
                color: #1D1D1D;
                line-height: 24px;
            }
        }
        .fourth-part-cont-item1{
            top: 100px;
            left: 80px;
        }
        .fourth-part-cont-item2{
            top: 100px;
            left: 542px;
        }
        .fourth-part-cont-item3{
            top: 100px;
            left: 988px;
        }
        .fourth-part-cont-item4{
            top: 370px;
            left: 307px;
        }
        .fourth-part-cont-item5{
            top: 370px;
            left: 763px;
        }
    }
  }
  .five-part{
    width: 100%;
    .five-part-cont{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .five-part-cont-item{
            flex-basis: 49%;
            height: 499px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            margin-bottom: 30px;
            position: relative;
            .cont-item-text{
                width: 100%;
                position: absolute;
                left: 0;
                top: 260px;
                box-sizing: border-box;
                padding: 30px;
                .cont-item-text-title{
                    width: 100%;
                    height: 30px;
                    font-weight: bold;
                    font-size: 24px;
                    color: #1D1D1D;
                    line-height: 36px;
                    margin-bottom: 20px;
                }
                .cont-item-text-info{
                    font-weight: 400;
                    font-size: 18px;
                    color: #1D1D1D;
                    line-height: 36px;
                }

            }
        }
        .five-part-cont-item1{
            background-image: url('../images/cdyy/son-xt-bg1.png');
        }
        .five-part-cont-item2{
            background-image: url('../images/cdyy/son-xt-bg2.png');
        }
        .five-part-cont-item3{
            background-image: url('../images/cdyy/son-xt-bg3.png');
        }
        .five-part-cont-item4{
            background-image: url('../images/cdyy/son-xt-bg4.png');
        }
        .five-part-cont-item5{
            background-image: url('../images/cdyy/son-xt-bg5.png');
        }
        .five-part-cont-item6{
            background-image: url('../images/cdyy/son-xt-bg6.png');
        }
        .five-part-cont-item7{
            background-image: url('../images/cdyy/son-xt-bg7.png');
        }
        .five-part-cont-item8{
            background-image: url('../images/cdyy/son-xt-bg8.png');
        }
    }
  }
}
</style>






